import React, { Component } from 'react';
import { Plus } from 'react-feather';
import {
  ToggleIcon,
  PaddedBox,
  Colors,
  RelativeBox,
} from '../styles/globalStyles';
import styled from '@emotion/styled';

const MoreBox = styled.div`
  background: ${props => (props.inverse ? Colors.grayDark : Colors.grayLight)};
  transition: all 0.5s;
  overflow: hidden;
  user-select: none;
`;

const ToggleControlledWrapper = styled.div`
  position: relative;
  transition: all 0.5s;
  top: 15px;
  left: -15px;
`;

const MoreBoxWithContent = ({ content, show, inverse }) => (
  <MoreBox style={{ maxHeight: show ? '1000px' : 0 }} inverse={inverse}>
    <PaddedBox>{content}</PaddedBox>
  </MoreBox>
);

const ToggleControls = ({ clickHandler, show, inverse }) => (
  <RelativeBox>
    <ToggleIcon onClick={clickHandler} inverse={inverse}>
      <Plus
        color={inverse ? Colors.fullBlack : Colors.white}
        style={{ transform: show ? `rotate(45deg)` : '' }}
      />
    </ToggleIcon>
  </RelativeBox>
);

class ToggleMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState(state => ({ show: !state.show }));
  }
  render() {
    return (
      <div>
        {this.props.content}
        <ToggleControlledWrapper>
          <ToggleControls
            clickHandler={this.toggle}
            show={this.state.show}
            inverse={this.props.inverse}
          />
        </ToggleControlledWrapper>
        <MoreBoxWithContent
          inverse={this.props.inverse}
          content={this.props.extra}
          show={this.state.show}
          clickHandler={this.toggle}
        />
      </div>
    );
  }
}

export default ToggleMore;
