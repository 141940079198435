import styled from '@emotion/styled';
import { Colors, Card, SubHeading } from './globalStyles';

const TalksHero = styled.section`
  position: relative;
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Card} {
    @media (min-width: 768px) {
      width: 666px;
    }
  }

  h3:first-of-type {
    margin-top: 0;
  }
`;

const TalksGrid = styled.article`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 100%;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 50%);
  }

  section {
    padding: 15px;
  }
`;

const TalksListSection = styled.article`
  padding: 60px 0;
`;

const TalkCard = styled.article`
  border: 1px solid ${Colors.grayLight};
  border-radius: 8px;
`;

const TalkCardHeader = styled.section`
  &.open {
    border-bottom: 1px solid ${Colors.grayLight};
  }
  display: grid;
  grid-template-columns: 80px 1fr 80px 80px;
  align-items: center;
`;

const TalkImage = styled.section`
  padding: 15px;
  img {
    border-radius: 50%;
  }
`;

const TalkTitle = styled.section`
  padding: 15px;
  ${SubHeading} {
    margin-bottom: 0;
  }
  h4 {
    margin-top: 0;
  }
`;

const TalkDate = styled.section`
  padding: 15px;
`;

const TalkAction = styled.section`
  padding: 15px;
  text-align: center;
`;

const TalkBody = styled.section`
  padding: 15px;
`;

const TalksListGrid = styled.article`
  display: grid;
  grid-template-columns: 100%;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 50%);
  }
  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, 33.34%);
  }
`;

const TalkListWrapper = styled.article`
  border: 1px solid ${Colors.grayLight};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 100%;
  @media (min-width: 768px) {
    grid-template-columns: 75% 25%;
  }
`;

const TalkListHeader = styled.header`
  padding: 15px;
`;

const TalksListItem = styled.section`
  margin: 15px;
  @media (min-width: 768px) {
    /* min-height: 120px; */
  }

  ${SubHeading} {
    margin-bottom: 0;
    font-weight: bold;
  }

  p {
    margin: 0;
  }

  h5 {
    margin: 0;
  }
`;

const TalksList = styled.div``;

const WorkshopsList = styled.div`
  background: ${Colors.grayLight};
`;

const TalksStatsContainer = styled.section`
  background: ${Colors.fullBlack};
  padding: 60px 0;
  color: white;
`;

const TalksStatsList = styled.article`
  display: grid;
  grid-template-columns: repeat(2, 50%);
`;

const TalksStatsDivContainer = styled.div`
  display: grid;
  align-items: center;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 50%);
  }
`;

const TalksStatsDiv = styled.div`
  padding: 30px;
`;

const TalksStat = styled.section`
  padding: 30px;
  text-align: center;

  p {
    margin-top: 0;
  }

  h3 {
    margin-bottom: 15px;
  }
`;

const TalksTitle = styled.h3`
  grid-column: 1 / -1;
  padding: 15px;
  margin-top: 0;
`;

const ImgGrid = styled.div`
  display: grid;
  margin-top: 30px;
  grid-template-columns: 1fr 1fr;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  grid-gap: 15px;

  div:first-of-type {
    grid-column-start: span 2;
  }

  div:nth-of-type(4) {
    grid-column-start: span 2;
  }

  div:nth-of-type(5) {
    grid-row-start: span 2;
    grid-column-start: span 2;
  }

  div:nth-of-type(8) {
    grid-column-start: span 2;
  }

  div:nth-of-type(11) {
    grid-column-start: span 2;
    @media (min-width: 768px) {
      grid-column-start: span 1;
    }
  }

  div:nth-of-type(12) {
    grid-column-start: span 2;
  }

  div:nth-of-type(18) {
    @media (min-width: 768px) {
      grid-column-start: span 2;
      grid-row-start: span 2;
    }
  }

  div:last-of-type {
    grid-column-start: span 2;
    @media (min-width: 768px) {
      grid-column-start: span 4;
    }
  }

  img {
    border-radius: 8px;
    border: 1px solid #eee;
    &:hover {
      border: 1px solid #666;
    }
  }
`;

export {
  TalksHero,
  TalksGrid,
  TalksListSection,
  TalkCard,
  TalkCardHeader,
  TalkImage,
  TalkTitle,
  TalkDate,
  TalkAction,
  TalkBody,
  TalksListGrid,
  TalksListItem,
  TalkListWrapper,
  TalksList,
  WorkshopsList,
  TalkListHeader,
  TalksStatsContainer,
  TalksStatsList,
  TalksStat,
  TalksStatsDivContainer,
  TalksStatsDiv,
  TalksTitle,
  ImgGrid,
};
