import React, { Component } from 'react';
import CountUp from 'react-countup';

import { Share2, Repeat } from 'react-feather';
import ToggleMore from '../components/toggleMore';
import { Container } from '../styles/globalStyles';
import {
  TalksStatsContainer,
  TalksStatsList,
  TalksStat,
  TalksStatsDivContainer,
  TalksStatsDiv,
} from '../styles/talksStyles';

const TalksRepeat = () => (
  <div>
    <h3>
      <Repeat size={30} style={{ marginRight: '30px' }} />
      Iterate
    </h3>
    <p>
      I like to iterate on my talks. The first version is an MVP and I usually
      deliver that to a smaller audience. The Q&A session is valuable feedback
      on gaps or things I could have explained better. I build these answers
      into the talk for the next revision. I've found that by the third time I
      deliver a talk I have a pretty tight presentation.
    </p>
  </div>
);

const TalksLearn = () => (
  <div>
    <h3>
      <Share2 size={30} style={{ marginRight: '30px' }} />
      Learn & Share
    </h3>
    <p>
      I believe teaching is a great way to learn. I try and make a talk out of
      any topic I've recently invested time in. I've personally acquired several
      skills in my career through tech talks and local meetup groups. I have a
      strong sense of giving back to the community. Tech talks are one of the
      ways I like to contribute.
    </p>
  </div>
);
class TalksStats extends Component {
  render() {
    const numOfYears = new Date().getFullYear() - 2013;

    return (
      <TalksStatsContainer>
        <Container>
          <TalksStatsDivContainer>
            <TalksStatsDiv>
              <ToggleMore
                inverse
                content={<TalksLearn />}
                extra={<TalksRepeat />}
              />
            </TalksStatsDiv>
            <TalksStatsDiv>
              <TalksStatsList>
                <TalksStat>
                  <h2>
                    <CountUp end={this.props.totalTalks} />
                  </h2>
                  <p>talks and workshops</p>
                </TalksStat>
                <TalksStat>
                  <h2>
                    <CountUp end={10} />
                  </h2>
                  <p>cities across 2 continents</p>
                </TalksStat>
                <TalksStat>
                  <h2>
                    <CountUp end={3} />
                  </h2>
                  <p>topics – JS, UI and UX</p>
                </TalksStat>
                <TalksStat>
                  <h2>
                    <CountUp end={numOfYears} />
                  </h2>
                  <p>years and counting</p>
                </TalksStat>
              </TalksStatsList>
            </TalksStatsDiv>
          </TalksStatsDivContainer>
        </Container>
      </TalksStatsContainer>
    );
  }
}

export default TalksStats;
