import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import TalksStats from '../partials/talksStats';

import Layout from '../components/layout';
import Seo from '../components/seo';
import TalksItem from '../components/talksItem';
import { Container, BackgroundDiv } from '../styles/globalStyles';
import {
  TalksHero,
  TalksListSection,
  TalksListGrid,
  TalkListWrapper,
  WorkshopsList,
  TalksList,
  TalksTitle,
  ImgGrid,
} from '../styles/talksStyles';

import ImageTalksHero from '../images/talkspage/talks-hero';
import Kayak1 from '../images/talkspage/kayak-1';
import Kayak2 from '../images/talkspage/kayak-2';
import Kayak3 from '../images/talkspage/kayak-3';

import Chewy1 from '../images/talkspage/chewy-1';
import Chewy2 from '../images/talkspage/chewy-2';
import Chewy3 from '../images/talkspage/chewy-3';
import Chewy4 from '../images/talkspage/chewy-4';
import Chewy5 from '../images/talkspage/chewy-5';
import Chewy6 from '../images/talkspage/chewy-6';

import Adriana from '../images/talkspage/adriana';
import Appworkshop from '../images/talkspage/appworkshop';

import Digitalsurgeons from '../images/talkspage/digitalsurgeons';

import Drupalcamp from '../images/talkspage/drupalcamp';
import Wordcamp from '../images/talkspage/wordcamp';
import Hackathon from '../images/talkspage/hackathon';
import Startupweekendnhv from '../images/talkspage/startupweekendnhv';

import Knockmedia from '../images/talkspage/knockmedia';
import Newhavenio from '../images/talkspage/newhavenio';
import Seeclickfix from '../images/talkspage/seeclickfix';

import Sketching from '../images/talkspage/sketching';

import Stacey from '../images/talkspage/stacey';
import Wordcamptalk from '../images/talkspage/wordcamptalk';

import Ux from '../images/talkspage/ux';
import Uxboston from '../images/talkspage/uxboston';
import Uxbostontalk from '../images/talkspage/uxbostontalk';

import Yale from '../images/talkspage/yale';

const TalksPage = ({ data }) => {
  const talks = data.filter(({ node: { category } }) =>
    category.includes('talk')
  );
  const workshops = data.filter(({ node: { category } }) =>
    category.includes('workshop')
  );
  const totalTalks = talks.length + workshops.length;

  return (
    <Layout>
      <Seo
        title="Talks - Vinay Raghu - Software Developer"
        keywords={[`gatsby`, `application`, `react`]}
      />
      <TalksHero>
        <BackgroundDiv>
          <ImageTalksHero />
        </BackgroundDiv>
        <Container />
      </TalksHero>
      <TalksStats totalTalks={totalTalks} />
      <TalksListSection>
        <Container>
          <TalkListWrapper>
            <TalksList>
              <TalksListGrid>
                <TalksTitle>Talks</TalksTitle>
                <TalksItem talks={talks} />
              </TalksListGrid>
            </TalksList>
            <WorkshopsList>
              <TalksTitle>Workshops</TalksTitle>
              <TalksItem talks={workshops} />
            </WorkshopsList>
          </TalkListWrapper>
          <ImgGrid>
            <div>
              <h3 style={{ marginTop: 0 }}>Hosting Meetups</h3>
              <p>
                I have great fondness for meetup groups and attribute a big
                chunk of my career success to folks I have met at such events.
                That is why I love hosting meetups and bringing people together.
                I've started, supported or helped revive a meetup group in every
                city that I have lived in. I have mentored participants at the{' '}
                <a
                  href="https://yalehackhealth.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Yale health hackathon
                </a>
                , when I lived in New Haven.
              </p>
            </div>

            <Kayak1 />
            <Knockmedia />
            <Kayak2 />

            <Chewy2 />
            <Ux />
            <Adriana />

            <Uxbostontalk />
            <Wordcamp />
            <Kayak3 />

            <Appworkshop />
            <Wordcamptalk />
            <Chewy6 />

            <Startupweekendnhv />

            <Hackathon />
            <Chewy5 />
            <Seeclickfix />

            <Chewy1 />
            <Uxboston />

            <Chewy3 />
            <Digitalsurgeons />
            <Yale />
            <Drupalcamp />
            <Sketching />

            <Newhavenio />
            <Stacey />
            <Chewy4 />
          </ImgGrid>
        </Container>
      </TalksListSection>
    </Layout>
  );
};

const TalksQuery = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulTalks {
          edges {
            node {
              title
              date
              event
              eventLink
              location
              category
            }
          }
        }
      }
    `}
    render={(data) => <TalksPage data={data.allContentfulTalks.edges} />}
  />
);

export default TalksQuery;
